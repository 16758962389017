import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProcessLayoutComponent } from '@stobag/mystobag-header';

import { CreateEventComponent } from './modules/event-manager/components/event-form/create-event/create-event.component';
import { ModifyEventComponent } from './modules/event-manager/components/event-form/modify-event/modify-event.component';
import { EventsComponent } from './modules/event-manager/components/events/events.component';
import { LoginComponent } from './modules/event-manager/components/login/login.component';
import { AddParticipantComponent } from './modules/event-manager/components/participant-form/create-participant/add-participant.component';
import { ModifyParticipantComponent } from './modules/event-manager/components/participant-form/modify-participant/modify-participant.component';
import { ParticipantListComponent } from './modules/event-manager/components/participant-list/participant-list.component';
import { EventManagerLayoutComponent } from './modules/event-manager/event-manager-layout.component';
import { AccessGuardService } from './modules/event-manager/services/access-guard.service';

const routes: Routes = [
    {
        path: '',
        component: EventManagerLayoutComponent,
        children: [
            {
                path: '',
                component: EventsComponent,
            },
            {
                path: ':eventId/participants',
                component: ParticipantListComponent,
            },
        ],
        canActivate: [AccessGuardService],
    },
    {
        path: '',
        component: ProcessLayoutComponent,
        children: [
            {
                path: 'create',
                component: CreateEventComponent,
            },
            {
                path: ':eventId/update',
                component: ModifyEventComponent,
            },
            {
                path: ':eventId/participants/add',
                component: AddParticipantComponent,
            },
            {
                path: ':eventId/participants/:participantId',
                component: ModifyParticipantComponent,
            },
        ],
        canActivate: [AccessGuardService],
    },
    {
        path: 'login',
        component: LoginComponent,
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
