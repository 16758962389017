import {
    ConfigService,
    CustomTranslateLoader,
    i18nMyStobagSharedDE,
    i18nMyStobagSharedEN,
    i18nMyStobagSharedFR,
    i18nMyStobagSharedIT,
    i18nMyStobagSharedNL,
    LanguageObjects,
} from '@stobag/mystobag-shared';
import { HttpBackend } from "@angular/common/http";

const languageObjects: LanguageObjects = {
    en: [i18nMyStobagSharedEN],
    de: [i18nMyStobagSharedDE],
    it: [i18nMyStobagSharedIT],
    fr: [i18nMyStobagSharedFR],
    nl: [i18nMyStobagSharedNL],
};

export function translateLoaderFactory(http: HttpBackend, config: ConfigService) {
    return new CustomTranslateLoader(http, config, languageObjects);
}

