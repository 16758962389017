import { Component, OnInit } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '@stobag/mystobag-shared';
import { CookieService } from 'ngx-cookie-service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
    constructor(
        private cookieService: CookieService,
        private translateService: TranslateService,
        private authenticationService: AuthenticationService,
        private sanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry,
        private dateAdapter: DateAdapter<never>, // Must inject dateAdapter here in order to create the service once
    ) {
        this.configureCustomMatIcons();
    }

    ngOnInit() {
        this.setTokenFromCookie();
        this.setLanguage();
    }

    private setTokenFromCookie() {
        const token = this.cookieService.get('accessToken');
        if (token) {
            localStorage.setItem('token', `Bearer ${token}`);
        }
    }

    private setLanguage() {
        const language = this.authenticationService.getLanguage();
        this.translateService.use(language);
    }

    private configureCustomMatIcons() {
        this.addCustomIconToRegistry('catalog', true);
        this.addCustomIconToRegistry('bolts');
        this.addCustomIconToRegistry('technical-support');
    }

    private addCustomIconToRegistry(iconName: string, localAsset = false) {
        const baseIconUrl = localAsset ? `/assets/img/links/` : '/static/icons/';
        const safeResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            `${baseIconUrl}${iconName}.svg`,
        );
        this.matIconRegistry.addSvgIcon(iconName, safeResourceUrl);
    }
}
