import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@stobag/mystobag-shared';
import { Observable } from 'rxjs';

import { WorkshopDTO } from '../models/workshop-dto';

@Injectable({ providedIn: 'root' })
export class WorkshopService {
    backendUrl = 'event-manager/api/workshop';

    constructor(private http: HttpClient, private loadingSpinnerService: LoadingSpinnerService) {}

    getWorkshops(): Observable<WorkshopDTO[]> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<WorkshopDTO[]>(this.backendUrl),
            'get-workshops',
        );
    }

    getWorkshopById(id: string): Observable<WorkshopDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<WorkshopDTO>(`${this.backendUrl}/${id}`),
            'get-workshop',
        );
    }

    getDraftWorkshopById(id: string): Observable<WorkshopDTO> {
        const request = this.http.get<WorkshopDTO>(`${this.backendUrl}/${id}`, {
            params: {
                stage: 'DRAFT',
            },
        });
        return this.loadingSpinnerService.withMasterLoader(request, 'get-workshop');
    }
}
