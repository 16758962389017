<shared-subheader *ngIf="isSubheaderUpdated$ | async" [transparent]="true"></shared-subheader>
<div class="content-container" *ngIf="event$ | async as event">
    <ng-container *ngIf="event.capacity - event.participantCount > 0 || modifyView">
        <ng-container *ngIf="formGroup$ | async as formGroup">
            <ng-container *ngIf="accounts$ | async as accounts">
                <form [formGroup]="formGroup">
                    <mat-form-field appearance="legacy" class="account-control">
                        <mat-label>{{
                            'event-manager.participant-list.account' | translate
                        }}</mat-label>
                        <mat-select formControlName="account">
                            <mat-option>
                                <ngx-mat-select-search
                                    #accFilter
                                    [placeholderLabel]="'shared.salesItem.search' | translate"
                                    [noEntriesFoundLabel]="
                                        'shared.salesItem.errorMessage' | translate
                                    "
                                    formControlName="accountFilter">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let account of accounts" [value]="account">
                                {{ account.name }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.get('account').hasError('required')">
                            {{ 'workshop.requiredError' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field appearance="legacy" class="user-control">
                        <mat-label>{{ 'workshop.user' | translate }}</mat-label>
                        <mat-select
                            formControlName="user"
                            [disabled]="!formGroup.get('account').value">
                            <mat-option>
                                <ngx-mat-select-search
                                    #usrFilter
                                    [placeholderLabel]="'shared.salesItem.search' | translate"
                                    [noEntriesFoundLabel]="
                                        'shared.salesItem.errorMessage' | translate
                                    "
                                    formControlName="userFilter">
                                </ngx-mat-select-search>
                            </mat-option>
                            <mat-option *ngFor="let user of users$ | async" [value]="user">
                                {{
                                    user.firstName
                                        ? user.firstName + ' ' + user.lastName
                                        : user.name
                                }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.get('account').hasError('required')">
                            {{ 'workshop.requiredError' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field
                        *ngIf="formGroup.get('meal')"
                        appearance="legacy"
                        class="meal-control">
                        <mat-label>{{ 'workshop.meal' | translate }}</mat-label>
                        <mat-select formControlName="meal">
                            <mat-option *ngFor="let meal of mealOptions" [value]="meal">
                                {{ 'workshop.' + meal | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.get('meal').hasError('required')">
                            {{ 'workshop.requiredError' | translate }}
                        </mat-error>
                    </mat-form-field>
                    <mat-form-field
                        *ngIf="formGroup.get('registrationStatus')"
                        appearance="legacy"
                        class="status-control">
                        <mat-label>{{ 'workshop.status' | translate }}</mat-label>
                        <mat-select formControlName="registrationStatus">
                            <mat-option *ngFor="let status of statuses" [value]="status">
                                {{ 'event-manager.participant-list.' + status | translate }}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="formGroup.get('registrationStatus').hasError('required')">
                            {{ 'workshop.requiredError' | translate }}
                        </mat-error>
                    </mat-form-field>
                </form>
            </ng-container>
            <div class="save">
                <button
                    mat-flat-button
                    class="primary-button"
                    (click)="onSave(event.id, formGroup)">
                    {{ 'workshop.save' | translate }}
                </button>
            </div>
        </ng-container>
    </ng-container>
</div>
