import { ChangeDetectionStrategy, Component, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { Subscription } from 'rxjs';

import { EventListService } from '../../services/event-list.service';

@Component({
    selector: 'app-events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [EventListService],
})
export class EventsComponent implements OnDestroy {
    private subscription: Subscription;

    constructor(
        private translateService: TranslateService,
        private subheaderService: MyStobagSubheaderService,
    ) {
        this.subscription = this.translateService
            .get('event-manager.event-list.title')
            .subscribe(title => this.subheaderService.updatePageName(title));
    }

    ngOnDestroy() {
        this.subscription?.unsubscribe();
    }
}
