import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { map, Observable, shareReplay, switchMap, tap } from 'rxjs';

import { EventStatus } from '../../../enums/event-status';
import { EventWorkshopParticipants } from '../../../models/event-workshop-participants';
import { WorkshopDTO } from '../../../models/workshop-dto';
import { EventService } from '../../../services/event.service';

@Component({
    selector: 'app-modify-event',
    templateUrl: '../event-form.component.html',
    styleUrls: ['../event-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModifyEventComponent implements OnInit {
    workshops$: Observable<WorkshopDTO[]>;
    title$: Observable<string>;
    eventStatuses = [EventStatus.Active, EventStatus.Inactive];
    formGroup: FormGroup;
    now = new Date();
    eventId: string;

    constructor(
        private eventService: EventService,
        private formBuilder: FormBuilder,
        private activatedRoute: ActivatedRoute,
        private dialogService: DialogService,
        private router: Router,
        private translate: TranslateService,
        private subheaderService: MyStobagSubheaderService,
    ) {}

    ngOnInit() {
        this.workshops$ = this.activatedRoute.paramMap.pipe(
            map(paramMap => paramMap.get('eventId')),
            tap(eventId => (this.eventId = eventId)),
            switchMap(eventId => this.eventService.getEventById(eventId)),
            tap(event => this.createFormGroup(event)),
            map(event => [event.workshop]),
            shareReplay(1),
        );
        this.title$ = this.translate
            .get('workshop.modifyWorkshop')
            .pipe(tap(translation => this.subheaderService.updatePageName(translation)));
    }

    createFormGroup(event: EventWorkshopParticipants) {
        this.formGroup = this.formBuilder.group({
            categoryId: new FormControl({ value: event.workshop.id, disabled: true }, [
                Validators.required,
            ]),
            eventDate: new FormControl(
                { value: event.eventDate, disabled: event.participantCount > 0 },
                [Validators.required],
            ),
            start: new FormControl({ value: event.start, disabled: event.participantCount > 0 }, [
                Validators.required,
            ]),
            end: new FormControl({ value: event.end, disabled: event.participantCount > 0 }, [
                Validators.required,
            ]),
            capacity: new FormControl({ value: event.capacity, disabled: false }, [
                Validators.required,
                Validators.min(event.participantCount),
            ]),
            registrationDeadline: new FormControl(
                { value: event.registrationDeadline, disabled: false },
                [Validators.required],
            ),
            status: new FormControl({ value: event.status, disabled: false }, [
                Validators.required,
            ]),
        });
    }

    saveEvent() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        const requestBody = this.formGroup.getRawValue();
        this.eventService.updateEvent(this.eventId, requestBody).subscribe({
            next: () => {
                const msg = this.translate.instant('workshop.successfulEventModification');
                this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
                this.router.navigate(['/']);
            },
            error: () => {
                const msg = this.translate.instant('workshop.eventModificationError');
                this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
            },
        });
    }
}
