import { EventWorkshopParticipants } from '../models/event-workshop-participants';
import { UserDTO } from '../models/user-dto';

export class UserFilterUtil {
    static filterOutParticipants(users: UserDTO[], event: EventWorkshopParticipants): UserDTO[] {
        return users.filter(user =>
            event.participants.every(p => {
                const userIdMatches = Boolean(p.userId) && p.userId === user.username;
                const contactIdMatches =
                    Boolean(p.contactObjectId) && p.contactObjectId === user.contactObjectId;
                return !userIdMatches && !contactIdMatches;
            }),
        );
    }
}
