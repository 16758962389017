import { DatePipe } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { GlobalErrorHandler } from '@stobag/mystobag-shared';

/**
 * CORE MODULE
 *
 * Place here services, directives, pipes, components (e.g. layout components)
 * which are used in all feature modules.
 */

@NgModule({
    imports: [RouterModule],
    providers: [{ provide: ErrorHandler, useClass: GlobalErrorHandler }, DatePipe],
})
export class CoreModule {}
