import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SearchFieldDefBase } from '@stobag/mystobag-header';
import { Observable, of } from 'rxjs';

import { EventStatus } from '../../../enums/event-status';
import { EventListService } from '../../../services/event-list.service';

@Component({
    selector: 'app-event-list-actions-bar',
    templateUrl: './event-list-actions-bar.component.html',
    styleUrls: ['./event-list-actions-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventListActionsBarComponent implements OnInit {
    searchBarFieldDefs$: Observable<Array<SearchFieldDefBase<unknown>>> = of([]);

    constructor(private eventListService: EventListService, private router: Router) {}

    ngOnInit() {
        this.searchBarFieldDefs$ = this.eventListService.getSearchBarFieldDefs$();
    }

    onAddEvent() {
        this.router.navigate(['/create']);
    }

    onSearchFilterChange({ category, eventStatus }: Record<string, unknown>) {
        this.eventListService.setEventListFilter({
            category: category as string[],
            status: eventStatus as EventStatus[],
        });
    }
}
