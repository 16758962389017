import { Injectable, OnDestroy } from '@angular/core';
import { SearchSelectField, SelectOption } from '@stobag/mystobag-header';
import { BehaviorSubject, Observable, Subscription, switchMap } from 'rxjs';
import { combineLatest } from 'rxjs';
import { filter, map, shareReplay, tap } from 'rxjs/operators';

import { EventStatus } from '../enums/event-status';
import { EventWithWorkshop } from '../models/event-with-workshop';
import { WorkshopDTO } from '../models/workshop-dto';
import { EventService } from './event.service';

const availableEventStatusFilters: SelectOption[] = [
    {
        label: 'workshop.ACTIVE',
        key: EventStatus.Active,
    },
    {
        label: 'workshop.INACTIVE',
        key: EventStatus.Inactive,
    },
    {
        label: 'workshop.COMPLETED',
        key: EventStatus.Completed,
    },
];

export interface EventListFilter {
    category: string[];
    status: EventStatus[];
}

@Injectable()
export class EventListService implements OnDestroy {
    private refreshEvents = new BehaviorSubject<void>(null);
    private eventListFilter = new BehaviorSubject<EventListFilter>(null);
    private subscription = new Subscription();
    private loadEvents$ = this.eventService.getEvents();

    constructor(private eventService: EventService) {}

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    getEvents$(): Observable<EventWithWorkshop[]> {
        return combineLatest([
            this.eventListFilter.asObservable().pipe(filter(event => Boolean(event))),
            this.refreshEvents.asObservable(),
        ]).pipe(
            switchMap(([filter]) =>
                this.loadEvents$.pipe(map(events => filterEvents(events, filter))),
            ),
        );
    }

    getSearchBarFieldDefs$() {
        return this.getCategoryOptions$().pipe(
            map(categoryOptions => [
                new SearchSelectField({
                    key: 'category',
                    multiple: true,
                    label: 'workshop.workshopCategory',
                    options: categoryOptions,
                }),
                new SearchSelectField({
                    key: 'eventStatus',
                    label: 'workshop.status',
                    multiple: true,
                    options: availableEventStatusFilters,
                    initialValue: [
                        availableEventStatusFilters[0].key,
                        availableEventStatusFilters[1].key,
                    ],
                }),
            ]),
        );
    }

    setEventListFilter(filter: EventListFilter) {
        this.eventListFilter.next(filter);
    }

    deleteEvent(eventId: string): Observable<void> {
        return this.eventService.deleteEvent(eventId).pipe(tap(() => this.refreshEvents.next()));
    }

    duplicateEvent(eventId: string): Observable<void> {
        return this.eventService.duplicateEvent(eventId).pipe(tap(() => this.refreshEvents.next()));
    }

    private getCategoryOptions$(): Observable<SelectOption[]> {
        return this.loadEvents$.pipe(
            map(events => events.map(event => event.workshop)),
            map(workshops => createOptions(workshops)),
            map(selectOptions => filterOptions(selectOptions)),
        );
    }
}

function filterEvents(events: EventWithWorkshop[], eventListFilter: EventListFilter) {
    if (!events || events.length === 0 || !eventListFilter) {
        return events;
    } else {
        const category = eventListFilter.category;
        const status = eventListFilter.status;
        return events
            .filter(
                event => !category || category.length === 0 || category.includes(event.categoryId),
            )
            .filter(event => !status || status.length === 0 || status.includes(event.status));
    }
}

function createOptions(workshops: WorkshopDTO[]): SelectOption[] {
    return workshops.map(workshop => ({
        key: workshop.id,
        label: `${workshop.category} (${workshop.internalTitle})`,
    }));
}

function filterOptions(options: SelectOption[]): SelectOption[] {
    return options.filter((option, index) => options.map(o => o.key).indexOf(option.key) === index);
}
