import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import {
    AuthenticationService,
    CONFIG_SERVICE_CONFIGURATION,
    ConfigServiceConfiguration,
    HttpInterceptorService,
    initialize,
    MyStobagRedirectService,
} from '@stobag/mystobag-shared';
import { NgxPermissionsModule, NgxPermissionsService } from 'ngx-permissions';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { translateLoaderFactory } from './modules/core/util/translate-loader-factory';
import { EventManagerModule } from './modules/event-manager/event-manager.module';
import { authenticationServiceFactory } from './modules/event-manager/utils/authentication.service.factory';
import { config } from './modules/event-manager/utils/config';

class NoOpErrorHandler extends ErrorHandler {}

class MockConfigService {
    getConfig() {
        return config;
    }
    initialize(): void {
        console.log('Initializing...');
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpBackend, MockConfigService],
            },
        }),
        NgxPermissionsModule.forRoot(),
        EventManagerModule,
        AppRoutingModule,
    ],
    providers: [
        {
            provide: CONFIG_SERVICE_CONFIGURATION,
            useFactory: () => {
                const configuration: Partial<ConfigServiceConfiguration> = {
                    serviceName: 'events',
                    verifyToken: true,
                };
                return configuration;
            },
        },
        MockConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: initialize,
            deps: [MockConfigService],
            multi: true,
        },
        {
            provide: AuthenticationService,
            useFactory: authenticationServiceFactory,
            deps: [HttpClient, NgxPermissionsService, MyStobagRedirectService],
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true },
        { provide: ErrorHandler, useClass: NoOpErrorHandler },
        DatePipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
