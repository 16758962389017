<shared-table
    *ngIf="events$ | async as events; else loadingOrError"
    [columnDefs]="columnDefs"
    [data]="events"
    [paginationLength]="events.length"
    [emptyIcon]="emptyIcon"
    emptyText="event-manager.event-list.emptyList"></shared-table>

<ng-template #loadingOrError>
    <div>
        *
    </div>
</ng-template>
