import {Injectable} from "@angular/core";
import {CanActivate, Router} from "@angular/router";
import {AuthenticationService} from "@stobag/mystobag-shared";
import { catchError, map } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AccessGuardService implements CanActivate {

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
  }

  canActivate(): boolean {
    const isAuthenticated = this.authenticationService.isAuthenticated();
    if (isAuthenticated) {
      return true;
    } else {
      this.authenticationService.refreshTokenV2().pipe(
        map(() => ''),
        catchError(() => 'login')
      ).subscribe(nextUrl => this.router.navigateByUrl(nextUrl));      
    }
  }
}
