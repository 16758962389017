import { DatePipe } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoadingSpinnerService } from '@stobag/mystobag-shared';
import { map, Observable } from 'rxjs';

import { EventDTO } from '../models/event-dto';
import { EventRequest } from '../models/event-request';
import { EventWithWorkshop } from '../models/event-with-workshop';
import { EventWorkshopParticipants } from '../models/event-workshop-participants';
import { ModifyParticipantRequest } from '../models/modify-participant-request';
import { ParticipantRequest } from '../models/participant-request';

@Injectable({ providedIn: 'root' })
export class EventService {
    backendUrl = 'event-manager/api/event';

    constructor(
        private http: HttpClient,
        private loadingSpinnerService: LoadingSpinnerService,
        private datePipe: DatePipe,
    ) {}

    getEvents() {
        return this.loadingSpinnerService
            .withMasterLoader(this.http.get<EventWithWorkshop[]>(this.backendUrl), 'get-events')
            .pipe(map(events => events.map(event => this.fixDates<EventWithWorkshop>(event))));
    }

    getEventById(eventId: string) {
        return this.loadingSpinnerService
            .withMasterLoader(
                this.http.get<EventWorkshopParticipants>(`${this.backendUrl}/${eventId}`),
                `get-event-${eventId}`,
            )
            .pipe(map(event => this.fixDates<EventWorkshopParticipants>(event)));
    }

    getEventsByWorkshopCategory(categoryId: string): Observable<EventWithWorkshop[]> {
        const params = new HttpParams().append('categoryId', categoryId);
        return this.loadingSpinnerService
            .withMasterLoader(
                this.http.get<EventWithWorkshop[]>(this.backendUrl, { params }),
                `get-event-by-workshop-category-${categoryId}`,
            )
            .pipe(map(events => events.map(event => this.fixDates<EventWithWorkshop>(event))));
    }

    createEvent(request: EventRequest) {
        const formattedRequest = this.formatCreateRequest(request);
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<EventRequest>(this.backendUrl, formattedRequest),
            'create-event',
        );
    }

    updateEvent(eventId: string, request: EventRequest) {
        const formattedRequest = this.formatCreateRequest(request);
        return this.loadingSpinnerService.withMasterLoader(
            this.http.put<EventRequest>(`${this.backendUrl}/${eventId}`, formattedRequest),
            `update-event-${eventId}`,
        );
    }

    deleteEvent(eventId: string) {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.delete<void>(`${this.backendUrl}/${eventId}`),
            `delete-event-${eventId}`,
        );
    }

    duplicateEvent(eventId: string) {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<void>(`${this.backendUrl}/${eventId}`, null),
            `duplicate-event-${eventId}`,
        );
    }

    registerParticipants(eventId: string, participantRequest: ParticipantRequest) {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.post<EventWithWorkshop>(
                `${this.backendUrl}/${eventId}/participant`,
                participantRequest,
            ),
            `register-participants-${eventId}`,
        );
    }

    updateParticipant(
        eventId: string,
        participantId: string,
        request: ModifyParticipantRequest,
    ): Observable<EventWithWorkshop> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.put<EventWorkshopParticipants>(
                `${this.backendUrl}/${eventId}/participant/${participantId}`,
                request,
            ),
            `update-participant-${participantId}`,
        );
    }

    getParticipantsAsCsv(eventId: string): Observable<Blob> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get(`${this.backendUrl}/${eventId}/participant`, { responseType: 'blob' }),
            `get-participants-${eventId}`,
        );
    }

    private formatCreateRequest(request: EventRequest): EventRequest {
        return {
            categoryId: request.categoryId,
            eventDate: this.datePipe.transform(request.eventDate, 'yyyy-MM-dd'),
            start: this.datePipe.transform(
                this.formatTime(request.start, request.eventDate),
                'HH:mm:ss',
                'UTC',
            ),
            end: this.datePipe.transform(
                this.formatTime(request.end, request.eventDate),
                'HH:mm:ss',
                'UTC',
            ),
            capacity: request.capacity,
            registrationDeadline: `${this.datePipe.transform(
                request.registrationDeadline,
                'yyyy-MM-dd',
            )}T00:00:00`,
            status: request.status,
        };
    }

    private formatTime(time: Date | string, date: Date | string): Date {
        const formattedTime = new Date(time);
        const formattedDate = new Date(date);
        formattedDate.setHours(formattedTime.getHours());
        formattedDate.setMinutes(formattedTime.getMinutes());
        return formattedDate;
    }

    private fixDates<T extends EventDTO>(event: T): T {
        return {
            ...event,
            eventDate: new Date(`${event.eventDate}T${event.start}Z`),
            start: new Date(`${event.eventDate}T${event.start}Z`),
            end: new Date(`${event.eventDate}T${event.end}Z`),
            registrationDeadline: new Date(`${event.registrationDeadline}Z`),
        };
    }
}
