import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
    CustomDatePipe,
    StatusLabelCellDef,
    StatusLabelType,
    TableColumnDef,
    TextCellDef,
} from '@stobag/mystobag-shared';

import { EventStatus } from '../enums/event-status';
import { EventWithWorkshop } from '../models/event-with-workshop';

@Injectable({ providedIn: 'root' })
export class WorkshopEventUtilsService {
    constructor(private datePipe: CustomDatePipe, private translateService: TranslateService) {}

    getEventListPageColumns(): Array<TableColumnDef<EventWithWorkshop>> {
        return [
            this.buildWorkshopCategoryColumn(),
            this.buildInternalTitleColumn(),
            this.buildDateColumn(),
            this.buildTimeColumn(),
            this.buildLocationColumn(),
            this.buildCapacityColumn(),
            this.buildRegistrationsColumn(),
            this.buildAvailableSlotsColumn(),
            this.buildRegistrationDeadlineColumn(),
            this.buildStatusColumn(),
        ];
    }

    getWorkshopDetailsEventList() {
        return [
            this.buildDateColumn(),
            this.buildTimeColumn(),
            this.buildRegistrationDeadlineColumn(),
            this.buildLocationColumn(),
            this.buildAvailableSlotsColumn(),
        ];
    }

    private buildWorkshopCategoryColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'event-category',
            headerCellDef: 'workshop.workshopCategory',
            cellDef: ({ workshop }) => new TextCellDef(workshop.category),
        };
    }

    private buildInternalTitleColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'event-internal-title',
            headerCellDef: 'workshop.internalTitle',
            cellDef: ({ workshop }) => new TextCellDef(workshop.internalTitle),
        };
    }

    private buildDateColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'date',
            headerCellDef: 'workshop.date',
            cellDef: ({ eventDate }) => new TextCellDef(this.datePipe.transform(eventDate)),
        };
    }

    private buildTimeColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'time',
            headerCellDef: 'workshop.time',
            cellDef: ({ start, end }) => {
                return new TextCellDef(this.formatTimePeriod(start, end));
            },
        };
    }

    private buildLocationColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'location',
            headerCellDef: 'workshop.location',
            cellDef: ({ workshop }) => new TextCellDef(workshop.workshopPlace.city),
        };
    }

    private buildCapacityColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'capacity',
            headerCellDef: 'workshop.capacity',
            cellDef: ({ capacity }) => new TextCellDef(capacity.toString()),
        };
    }

    private buildRegistrationsColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'registrations',
            headerCellDef: 'event-manager.event-list.registrations',
            cellDef: ({ participantCount }) => new TextCellDef(participantCount.toString()),
        };
    }

    private buildAvailableSlotsColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'availableSlots',
            headerCellDef: 'event-manager.event-list.availableSlots',
            cellDef: ({ capacity, participantCount, registrationDeadline }) => {
                const availableSlots = capacity - participantCount;
                const hasNoAvailableSlots = availableSlots === 0;
                const registrationOver = isRegistrationOver(registrationDeadline);
                const type: StatusLabelType =
                    hasNoAvailableSlots || registrationOver ? undefined : 'success';
                const label = this.getAvailableSlotsLabel(availableSlots);

                return new StatusLabelCellDef(label, { type });
            },
        };
    }

    private getAvailableSlotsLabel(availableSlots: number) {
        switch (availableSlots) {
            case 0:
                return this.translateService.instant('event-manager.event-list.fullyBooked');
            case 1:
                return this.translateService.instant('event-manager.event-list.oneSlotLeft');
            default:
                return this.translateService.instant('event-manager.event-list.valueSlotsLeft', {
                    slots: availableSlots,
                });
        }
    }

    private buildRegistrationDeadlineColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'registrationDeadline',
            headerCellDef: 'event-manager.event-list.registrationDeadline',
            cellDef: ({ registrationDeadline }) => {
                const registrationOver = isRegistrationOver(registrationDeadline);
                return new TextCellDef(this.datePipe.transform(registrationDeadline), {
                    infoText: registrationOver
                        ? this.translateService.instant('workshop.registrationDeadlineExpired')
                        : undefined,
                });
            },
        };
    }

    private buildStatusColumn(): TableColumnDef<EventWithWorkshop> {
        return {
            columnDef: 'status',
            headerCellDef: 'workshop.status',
            cellDef: ({ status }) => {
                const label = this.translateService.instant(`workshop.${status}`);
                return new StatusLabelCellDef(label, {
                    type: this.getStatusType(status),
                });
            },
        };
    }

    private getStatusType(status: EventStatus): StatusLabelType {
        switch (status) {
            case EventStatus.Active:
                return 'info';
            case EventStatus.Inactive:
                return 'error';
            default:
                return null;
        }
    }

    private formatTimePeriod(start: Date | string, end: Date | string) {
        const startTime = this.datePipe.transform(start, 'shortTime');
        const endTime = this.datePipe.transform(end, 'shortTime');
        return this.translateService.instant('event-manager.event-list.timePeriod', {
            start: startTime,
            end: endTime,
        });
    }
}

export function isRegistrationOver(deadline: Date | string): boolean {
    const now = new Date();
    now.setHours(0, 0, 0, 0);
    return new Date(deadline) < now;
}
