import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { Observable, shareReplay, tap } from 'rxjs';

import { EventStatus } from '../../../enums/event-status';
import { WorkshopDTO } from '../../../models/workshop-dto';
import { EventService } from '../../../services/event.service';
import { WorkshopService } from '../../../services/workshop.service';

@Component({
    selector: 'app-create-event',
    templateUrl: '../event-form.component.html',
    styleUrls: ['../event-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateEventComponent implements OnInit {
    workshops$: Observable<WorkshopDTO[]>;
    title$: Observable<string>;
    eventStatuses = [EventStatus.Active, EventStatus.Inactive];
    formGroup: FormGroup;
    now = new Date();

    constructor(
        private workshopService: WorkshopService,
        private eventService: EventService,
        private formBuilder: FormBuilder,
        private dialogService: DialogService,
        private router: Router,
        private translate: TranslateService,
        private subheaderService: MyStobagSubheaderService,
    ) {}

    ngOnInit() {
        this.workshops$ = this.workshopService.getWorkshops().pipe(shareReplay(1));
        this.formGroup = this.formBuilder.group({
            categoryId: new FormControl({ value: null, disabled: false }, [Validators.required]),
            eventDate: new FormControl({ value: null, disabled: false }, [Validators.required]),
            start: new FormControl({ value: null, disabled: false }, [Validators.required]),
            end: new FormControl({ value: null, disabled: false }, [Validators.required]),
            capacity: new FormControl({ value: null, disabled: false }, [
                Validators.required,
                Validators.min(1),
            ]),
            registrationDeadline: new FormControl({ value: null, disabled: false }, [
                Validators.required,
            ]),
            status: new FormControl({ value: EventStatus.Inactive, disabled: false }, [
                Validators.required,
            ]),
        });
        this.title$ = this.translate
            .get('workshop.addWorkshop')
            .pipe(tap(translation => this.subheaderService.updatePageName(translation)));
    }

    saveEvent() {
        this.formGroup.updateValueAndValidity();
        this.formGroup.markAllAsTouched();

        if (this.formGroup.invalid) {
            return;
        }

        const requestBody = this.formGroup.getRawValue();
        this.eventService.createEvent(requestBody).subscribe({
            next: () => {
                const msg = this.translate.instant('workshop.successfulEventCreation');
                this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
                this.router.navigate(['/']);
            },
            error: () => {
                const msg = this.translate.instant('workshop.eventCreationError');
                this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
            },
        });
    }
}
