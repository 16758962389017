import { CommonModule, DatePipe } from '@angular/common';
import { HttpBackend } from '@angular/common/http';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ConfigModule, MystobagSharedModule } from '@stobag/mystobag-shared';
import { MatTimepickerModule } from 'mat-timepicker';

import { CoreModule } from '../core/core.module';
import { translateLoaderFactory } from '../core/util/translate-loader-factory';
import { CreateEventComponent } from './components/event-form/create-event/create-event.component';
import { ModifyEventComponent } from './components/event-form/modify-event/modify-event.component';
import { EventListComponent } from './components/events/event-list/event-list.component';
import { EventListActionsBarComponent } from './components/events/event-list-actions-bar/event-list-actions-bar.component';
import { EventsComponent } from './components/events/events.component';
import { AddParticipantComponent } from './components/participant-form/create-participant/add-participant.component';
import { ModifyParticipantComponent } from './components/participant-form/modify-participant/modify-participant.component';
import { ParticipantListComponent } from './components/participant-list/participant-list.component';
import { EventManagerLayoutComponent } from './event-manager-layout.component';
import { LoginComponent } from './components/login/login.component';
import { MatMenuModule } from '@angular/material/menu';
import { MaterialModule } from '../core/material.module';
import { NgxPermissionsModule } from 'ngx-permissions';
import { FlexModule } from '@angular/flex-layout';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { MystobagHeaderModule } from '@stobag/mystobag-header';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ObserversModule } from '@angular/cdk/observers';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { config } from './utils/config';

class MockConfigService {
    getConfig() {
        return config;
    }
}

@NgModule({
    declarations: [
        EventManagerLayoutComponent,
        EventsComponent,
        EventListComponent,
        EventListActionsBarComponent,
        CreateEventComponent,
        ModifyEventComponent,
        ParticipantListComponent,
        AddParticipantComponent,
        ModifyParticipantComponent,
        LoginComponent,
    ],
    imports: [
        CoreModule,
        MaterialModule,
        MatDatepickerModule,
        MatTimepickerModule,
        MatMenuModule,
        RouterModule,        
        TranslateModule.forChild({
            loader: {
                provide: TranslateLoader,
                useFactory: translateLoaderFactory,
                deps: [HttpBackend, MockConfigService],
            },
        }),
        NgxPermissionsModule.forChild(),
        CommonModule,
        ConfigModule,
        FlexModule,
        NgxUiLoaderModule,
        MystobagSharedModule,
        MystobagHeaderModule,
        NgxMatSelectSearchModule,
        ObserversModule,
        FormsModule,
        ReactiveFormsModule
    ],
    providers: [
        DatePipe
    ],
    exports: [
        EventManagerLayoutComponent,
        EventsComponent,
        EventListComponent,
        EventListActionsBarComponent,
        CreateEventComponent,
        ModifyEventComponent,
        ParticipantListComponent,
        AddParticipantComponent,
        ModifyParticipantComponent,
        LoginComponent,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexModule,
        NgxUiLoaderModule,
        MystobagSharedModule,
        MystobagHeaderModule,
        NgxMatSelectSearchModule,
    ],
})
export class EventManagerModule {}
