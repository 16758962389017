import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { AccountDTO, DialogService, SnackbarType } from '@stobag/mystobag-shared';
import { combineLatest, map, Observable, of, shareReplay, switchMap, tap } from 'rxjs';
import { Meal } from '../../../enums/meal';
import { RegistrationStatus } from '../../../enums/registration-status';
import { EventWorkshopParticipants } from '../../../models/event-workshop-participants';
import { ModifyParticipantRequest } from '../../../models/modify-participant-request';
import { Participant } from '../../../models/participant';
import { UserDTO } from '../../../models/user-dto';
import { EventService } from '../../../services/event.service';
import { UserAndAccountService } from '../../../services/user-and-account.service';

interface ModifyParticipantFormValue {
    account: AccountDTO;
    user: UserDTO;
    meal?: Meal;
    registrationStatus: RegistrationStatus;
}

@Component({
    selector: 'app-stobag-modify-participant',
    templateUrl: '../participant-form.component.html',
    styleUrls: ['../participant-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModifyParticipantComponent implements OnInit {
    event$: Observable<EventWorkshopParticipants>;

    modifyView = true;
    accounts$: Observable<AccountDTO[]>;
    users$: Observable<UserDTO[]>;
    formGroup$: Observable<FormGroup>;
    isSubheaderUpdated$: Observable<string>;
    mealOptions = [Meal.NONE, Meal.MEAT, Meal.VEGETARIAN];
    statuses = [RegistrationStatus.REGISTERED, RegistrationStatus.UNREGISTERED];
    participantId: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private userAndAccountService: UserAndAccountService,
        private formBuilder: FormBuilder,
        private eventService: EventService,
        private translate: TranslateService,
        private dialogService: DialogService,
        private router: Router,
        private subheaderService: MyStobagSubheaderService,
    ) {}

    ngOnInit(): void {
        const paramMap$ = this.activatedRoute.paramMap;
        this.event$ = paramMap$.pipe(
            map(paramMap => paramMap.get('eventId')),
            switchMap(eventId => this.eventService.getEventById(eventId)),
            shareReplay(1),
        );
        this.formGroup$ = combineLatest([
            paramMap$.pipe(map(paramMap => paramMap.get('participantId'))),
            this.event$,
        ]).pipe(
            switchMap(([participantId, event]) =>
                this.getAndSetUserAndAccount(event, participantId),
            ),
            map(({ account, user, event, participant }) =>
                this.initFormGroup(event, account, user, participant),
            ),
        );
        this.isSubheaderUpdated$ = this.translate
            .get('workshop.addParticipant')
            .pipe(tap(translation => this.subheaderService.updatePageName(translation)));
    }

    private getAndSetUserAndAccount(event: EventWorkshopParticipants, participantId: string) {
        const participant = event.participants.find(p => p.id === participantId);
        this.participantId = participantId;
        return this.userAndAccountService.getAccount(participant.accountNumber).pipe(
            map(account => {
                this.accounts$ = of([account]);
                const user = {
                    email: null,
                    contactObjectId: participant.contactObjectId,
                    name: participant.name,
                    firstName: participant.firstname,
                    lastName: participant.lastname,
                };
                this.users$ = of([user]);
                return { account, user, participant, event };
            }),
        );
    }

    private initFormGroup(
        event: EventWorkshopParticipants,
        account: AccountDTO,
        user: UserDTO,
        participant: Participant,
    ): FormGroup {
        const formGroup = this.formBuilder.group({
            account: new FormControl({ value: account, disabled: true }, [Validators.required]),
            accountFilter: new FormControl(),
            user: new FormControl({ value: user, disabled: true }, [Validators.required]),
            userFilter: new FormControl(),
            registrationStatus: new FormControl(participant.registrationStatus, [
                Validators.required,
            ]),
        });
        if (event.workshop.isLunchIncluded) {
            formGroup.addControl('meal', new FormControl(participant.meal, [Validators.required]));
        }
        return formGroup;
    }

    getAccounts(accounts: AccountDTO[]) {
        return accounts;
    }

    onSave(eventId: string, formGroup: FormGroup) {
        formGroup.markAllAsTouched();
        formGroup.updateValueAndValidity();
        if (formGroup.invalid) {
            return;
        }
        const formValue = formGroup.getRawValue() as ModifyParticipantFormValue;
        const request: ModifyParticipantRequest = {
            meal: formValue.meal,
            status: formValue.registrationStatus,
        };
        this.eventService.updateParticipant(eventId, this.participantId, request).subscribe({
            next: () => {
                const msg = this.translate.instant('workshop.successfulParticipantRegistration');
                this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
                this.router.navigate([`/${eventId}/participants`]);
            },
            error: () => {
                const msg = this.translate.instant('workshop.participantRegistrationError');
                this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
            },
        });
    }
}
