<shared-subheader *ngIf="isSubheaderUpdated$ | async"></shared-subheader>
<div class="content-container" *ngIf="event$ | async as event">
    <mat-card class="workshop-details full-width">
        <div class="bold">{{ 'workshop.workshop' | translate }}:</div>
        <div>{{ event.workshop.category }}</div>
        <div class="bold">
            {{ 'workshop.date' | translate }}, {{ 'workshop.time' | translate }}:
        </div>
        <div>
            {{ event.start | sharedDatePipe: 'medium' }} -
            {{ event.end | sharedDatePipe: 'shortTime' }}
        </div>
        <div class="bold">{{ 'workshop.location' | translate }}:</div>
        <div>{{ event.workshop.workshopPlace.city }}</div>
        <div class="bold">{{ 'workshop.capacity' | translate }}:</div>
        <div>{{ event.capacity }}</div>
        <div class="bold">{{ 'event-manager.event-list.availableSlots' | translate }}:</div>
        <div>{{ availableSlots$ | async }}</div>
    </mat-card>
    <div class="text-link" *ngIf="event.participants?.length" (click)="downloadAsCsv(event.id)">
        <shared-icon icon="download"></shared-icon> Download CSV
    </div>
    <button
        mat-flat-button
        class="primary-button add-participant-button"
        (click)="addParticipant(event.id)"
        [disabled]="(availableSlots$ | async) === 0">
        {{ 'workshop.addParticipant' | translate }}
    </button>
</div>
<shared-table
    *ngIf="event$ | async as event"
    class="participant-table"
    [columnDefs]="columnDefs$ | async"
    [data]="event.participants"
    [paginationLength]="event.participants?.length"
    [emptyIcon]="emptyIcon"
    emptyText="event-manager.participant-list.emptyList"></shared-table>
