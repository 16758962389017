import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagLink } from '@stobag/mystobag-shared';

@Component({
    selector: 'app-event-manager-layout',
    template: `
        <shared-drawer-layout>
            <ng-container header-content>
                <shared-stobag-menu-button></shared-stobag-menu-button>
                <shared-user-menu-button hideAccount="true" hideProfile="true"></shared-user-menu-button>
            </ng-container>
            <ng-container drawer>
                <shared-drawer-content [majorLinks]="links"></shared-drawer-content>
            </ng-container>
            <router-outlet></router-outlet>
        </shared-drawer-layout>
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventManagerLayoutComponent {
    links: MyStobagLink[] = [
        {displayName: "Overview", url: '', permission: '', deniedPermissions: []}, 
        {displayName: "Create", url: 'create', permission: '', deniedPermissions: []},
    ];

    constructor(translateService: TranslateService) {
        this.links = [
            {displayName: translateService.instant('menu.overview'), url: '', permission: '', deniedPermissions: []}, 
            {displayName: translateService.instant('menu.create'), url: 'create', permission: '', deniedPermissions: []},
        ];
    }
}
