import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '@stobag/mystobag-shared';

import { config } from '../../utils/config';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
    constructor(private authenticationService: AuthenticationService, private router: Router) {
        const hasLoggedInUser = this.authenticationService.isAuthenticated();
        if (hasLoggedInUser) {
            this.router.navigateByUrl('');
        }
    }

    navigateToLogin() {
        window.location.href = this.getLoginUrl();
    }

    private getLoginUrl(): string {
        const recirectUri = window.location.origin;
        return `${config?.apiStobag}/authentication/stobag-login?redirectUri=${recirectUri}`;
    }
}
