import { HttpClient } from '@angular/common/http';

import {
  AuthenticationService,
  MyStobagRedirectService,
} from '@stobag/mystobag-shared';
import { NgxPermissionsService } from 'ngx-permissions';
import { config } from './config';

export function authenticationServiceFactory (http: HttpClient,
  permissionsService: NgxPermissionsService,
  redirectService: MyStobagRedirectService) {  
  return new AuthenticationService(http, permissionsService, redirectService, `${config?.apiStobag}/authentication/api`);
};
