import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import { AccountDTO, DialogService, SnackbarType } from '@stobag/mystobag-shared';
import {
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    map,
    Observable,
    shareReplay,
    startWith,
    switchMap,
    tap,
} from 'rxjs';

import { EventService } from '../../../services/event.service';
import { UserAndAccountService } from '../../../services/user-and-account.service';
import { UserDTO } from '../../../models/user-dto';
import { UserFilterUtil } from '../../../utils/user-filter-util';
import { EventWorkshopParticipants } from '../../../models/event-workshop-participants';
import { ParticipantRequest } from '../../../models/participant-request';
import { Meal } from '../../../enums/meal';
import { RegistrationStatus } from '../../../enums/registration-status';

interface AddParticipantFormValue {
    account: AccountDTO;
    user: UserDTO;
    meal?: Meal;
}

@Component({
    selector: 'app-stobag-add-participant',
    templateUrl: '../participant-form.component.html',
    styleUrls: ['../participant-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddParticipantComponent implements OnInit {
    event$: Observable<EventWorkshopParticipants>;

    modifyView = false;
    accounts$: Observable<AccountDTO[]>;
    users$: Observable<UserDTO[]>;
    formGroup$: Observable<FormGroup>;
    isSubheaderUpdated$: Observable<string>;
    mealOptions = [Meal.NONE, Meal.MEAT, Meal.VEGETARIAN];
    statuses = [RegistrationStatus.REGISTERED, RegistrationStatus.UNREGISTERED];

    constructor(
        private activatedRoute: ActivatedRoute,
        private userAndAccountService: UserAndAccountService,
        private formBuilder: FormBuilder,
        private eventService: EventService,
        private translate: TranslateService,
        private dialogService: DialogService,
        private router: Router,
        private subheaderService: MyStobagSubheaderService,
    ) {}

    ngOnInit(): void {
        this.event$ = this.activatedRoute.paramMap.pipe(
            map(paramMap => paramMap.get('eventId')),
            switchMap(eventId => this.eventService.getEventById(eventId)),
            shareReplay(1),
        );

        this.formGroup$ = this.event$.pipe(map(event => this.initFormGroup(event)));

        this.isSubheaderUpdated$ = this.translate
            .get('workshop.addParticipant')
            .pipe(tap(translation => this.subheaderService.updatePageName(translation)));
    }

    private initFormGroup(event: EventWorkshopParticipants) {
        const formGroup = this.formBuilder.group({
            account: new FormControl(null, [Validators.required]),
            accountFilter: new FormControl(),
            user: new FormControl(null, [Validators.required]),
            userFilter: new FormControl(),
        });
        if (event.workshop.isLunchIncluded) {
            formGroup.addControl('meal', new FormControl(null, [Validators.required]));
        }
        this.initValueChangeListeners(formGroup);
        return formGroup;
    }

    private initValueChangeListeners(formGroup: FormGroup) {
        this.accounts$ = combineLatest([
            this.userAndAccountService.getAccounts().pipe(shareReplay(1)),
            formGroup
                .get('accountFilter')
                .valueChanges.pipe(startWith(''), debounceTime(500), distinctUntilChanged()),
        ]).pipe(map(([accounts, searchKey]) => this.getAccounts(accounts, searchKey)));
        this.users$ = formGroup.get('account').valueChanges.pipe(
            tap(() => formGroup.get('user').setValue(null)),
            switchMap((account: AccountDTO) =>
                combineLatest([
                    this.event$,
                    this.userAndAccountService.getAccountUsers(account.accountNumber),
                ]),
            ),
            map(([event, users]) => UserFilterUtil.filterOutParticipants(users, event)),
        );
    }

    getAccounts(accounts: AccountDTO[], searchKey: string) {
        return searchKey && searchKey?.trim().length > 0
            ? accounts.filter(
                  acc =>
                      acc.accountNumber.includes(searchKey.toLowerCase()) ||
                      acc.name.toLowerCase().includes(searchKey.toLowerCase()),
              )
            : accounts;
    }

    onSave(eventId: string, formGroup: FormGroup) {
        formGroup.markAllAsTouched();
        formGroup.updateValueAndValidity();
        if (formGroup.invalid) {
            return;
        }
        const formValue = formGroup.getRawValue() as AddParticipantFormValue;
        const request: ParticipantRequest = {
            accountNumber: formValue.account.accountNumber,
            participants: [
                {
                    email: formValue.user.email,
                    contactObjectId: formValue.user.contactObjectId,
                    meal: formValue.meal,
                },
            ],
        };
        this.eventService.registerParticipants(eventId, request).subscribe({
            next: () => {
                const msg = this.translate.instant('workshop.successfulParticipantRegistration');
                this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
                this.router.navigate([`/${eventId}/participants`]);
            },
            error: () => {
                const msg = this.translate.instant('workshop.participantRegistrationError');
                this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
            },
        });
    }
}
