<shared-subheader *ngIf="title$ | async" [transparent]="true"></shared-subheader>
<div class="content-container" *ngIf="workshops$ | async as workshops">
    <form [formGroup]="formGroup">
        <mat-form-field appearance="legacy" class="full-width">
            <mat-label>{{ 'workshop.workshopCategory' | translate }}</mat-label>
            <mat-select formControlName="categoryId">
                <mat-option *ngFor="let workshop of workshops" [value]="workshop.id">
                    {{ workshop.category }} ({{workshop.internalTitle}})
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('categoryId').hasError('required')">
                {{ 'workshop.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="full-width">
            <mat-label>{{ 'workshop.date' | translate }}</mat-label>
            <input
                matInput
                id="eventDate"
                formControlName="eventDate"
                [matDatepicker]="$any(eventDate)"
                [min]="now" />
            <mat-error *ngIf="formGroup.get('eventDate').hasError('required')">
                {{ 'workshop.requiredError' | translate }}
            </mat-error>
            <mat-error *ngIf="formGroup.get('eventDate').hasError('matDatepickerMin')">
                {{ 'workshop.eventDateMinError' | translate }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="eventDate"></mat-datepicker-toggle>
            <mat-datepicker #eventDate></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="half-width">
            <mat-label>{{ 'workshop.startTime' | translate }}</mat-label>
            <input
                matTimepicker
                mode="24h"
                formControlName="start"
                required
                [maxDate]="formGroup.get('end')?.value" />
            <mat-error *ngIf="formGroup.get('start').hasError('required')">
                {{ 'workshop.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="half-width">
            <mat-label>{{ 'workshop.endTime' | translate }}</mat-label>
            <input
                matTimepicker
                mode="24h"
                formControlName="end"
                required
                [minDate]="formGroup.get('start')?.value" />
            <mat-error *ngIf="formGroup.get('end').hasError('required')">
                {{ 'workshop.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="full-width">
            <mat-label>{{ 'workshop.capacity' | translate }}</mat-label>
            <input matInput type="number" formControlName="capacity" min="1" />
            <mat-error *ngIf="formGroup.get('capacity').hasError('required')">
                {{ 'workshop.requiredError' | translate }}
            </mat-error>
            <mat-error *ngIf="formGroup.get('capacity').hasError('min')">
                {{ 'workshop.capacityMinError' | translate }}
            </mat-error>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="full-width">
            <mat-label>{{ 'event-manager.event-list.registrationDeadline' | translate }}</mat-label>
            <input
                matInput
                id="deadline"
                formControlName="registrationDeadline"
                [matDatepicker]="$any(deadline)"
                [min]="now"
                [max]="formGroup.get('eventDate')?.value" />
            <mat-error *ngIf="formGroup.get('registrationDeadline').hasError('required')">
                {{ 'workshop.requiredError' | translate }}
            </mat-error>
            <mat-error *ngIf="formGroup.get('registrationDeadline').hasError('matDatepickerMin')">
                {{ 'workshop.deadlineMinError' | translate }}
            </mat-error>
            <mat-error *ngIf="formGroup.get('registrationDeadline').hasError('matDatepickerMax')">
                {{ 'workshop.deadlineMaxError' | translate }}
            </mat-error>
            <mat-datepicker-toggle matSuffix [for]="deadline"></mat-datepicker-toggle>
            <mat-datepicker #deadline></mat-datepicker>
        </mat-form-field>
        <mat-form-field appearance="legacy" class="full-width">
            <mat-label>{{ 'workshop.status' | translate }}</mat-label>
            <mat-select formControlName="status">
                <mat-option *ngFor="let status of eventStatuses" [value]="status">
                    {{ 'workshop.' + status | translate }}
                </mat-option>
            </mat-select>
            <mat-error *ngIf="formGroup.get('status').hasError('required')">
                {{ 'workshop.requiredError' | translate }}
            </mat-error>
        </mat-form-field>
        <div class="create-event-actions">
            <button mat-flat-button class="primary-button" (click)="saveEvent()">
                {{ 'workshop.save' | translate }}
            </button>
        </div>
    </form>
</div>
