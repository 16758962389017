import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountDTO, LoadingSpinnerService } from '@stobag/mystobag-shared';
import { Observable, shareReplay } from 'rxjs';

import { UserDTO } from '../../event-manager/models/user-dto';

@Injectable({ providedIn: 'root' })
export class UserAndAccountService {
    userApiUrl = 'authentication/api/user';
    accountApiUrl = 'authentication/api/account/';

    constructor(private http: HttpClient, private loadingSpinnerService: LoadingSpinnerService) {}

    getAccountUsers(accountNumber: string): Observable<UserDTO[]> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<UserDTO[]>(`${this.userApiUrl}/account/${accountNumber}`),
            `get-users-${accountNumber}`,
        );
    }

    getAccounts(): Observable<AccountDTO[]> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http.get<AccountDTO[]>(`${this.accountApiUrl}`).pipe(shareReplay(1)),
            'get-accounts',
        );
    }

    getAccount(accountNumber: string): Observable<AccountDTO> {
        return this.loadingSpinnerService.withMasterLoader(
            this.http
                .get<AccountDTO>(`${this.accountApiUrl}/${accountNumber}`)
                .pipe(shareReplay(1)),
            `get-account-${accountNumber}`,
        );
    }
}
