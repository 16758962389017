import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import {
    ActionCellDef,
    DialogService,
    SnackbarType,
    TableColumnDef,
} from '@stobag/mystobag-shared';
import { filter, Subscription, switchMap } from 'rxjs';
import { EventWithWorkshop } from '../../../models/event-with-workshop';
import { EventListService } from '../../../services/event-list.service';
import { WorkshopEventUtilsService } from '../../../services/workshop-event-utils.service';

@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventListComponent implements OnInit, OnDestroy {
    emptyIcon = 'calendar-event';
    columnDefs: Array<TableColumnDef<EventWithWorkshop>>;
    events$ = this.eventListService.getEvents$();
    subscriptions = new Subscription();

    constructor(
        private subheaderService: MyStobagSubheaderService,
        private eventListService: EventListService,
        private router: Router,
        private translate: TranslateService,
        private dialogService: DialogService,
        private workshopEventUtilsService: WorkshopEventUtilsService,
    ) {}

    ngOnInit() {
        this.subheaderService.hideBackButton();
        const eventListPageColumns = this.workshopEventUtilsService.getEventListPageColumns();
        this.columnDefs = this.getColumnDefs(eventListPageColumns);
    }

    ngOnDestroy(): void {
        this.subscriptions?.unsubscribe();
    }

    addEvent(): void {
        this.router.navigate(['/create']);
    }

    duplicateEvent(eventId: string): void {
        this.subscriptions.add(
            this.eventListService.duplicateEvent(eventId).subscribe({
                next: () => {
                    const msg = this.translate.instant(
                        'event-manager.event-list.successfulDuplication',
                    );
                    this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
                },
                error: () => {
                    const msg = this.translate.instant('event-manager.event-list.duplicationError');
                    this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
                },
            }),
        );
    }

    editEvent(eventId: string): void {
        this.router.navigate([`/${eventId}/update`]);
    }

    deleteEvent(eventId: string): void {
        this.subscriptions.add(
            this.dialogService
                .openConfirmationDialog(
                    this.translate.instant('event-manager.event-list.confirmDeletion'),
                )
                .afterClosed()
                .pipe(
                    filter(response => Boolean(response)),
                    switchMap(() => this.eventListService.deleteEvent(eventId)),
                )
                .subscribe({
                    next: () => {
                        const msg = this.translate.instant(
                            'event-manager.event-list.successfulDeletion',
                        );
                        this.dialogService.openSnackbar(msg, SnackbarType.SUCCESS, 5);
                    },
                    error: () => {
                        const msg = this.translate.instant(
                            'event-manager.event-list.deletionError',
                        );
                        this.dialogService.openSnackbar(msg, SnackbarType.ERROR, 5);
                    },
                }),
        );
    }

    linkToParticipantList(eventId: string): void {
        this.router.navigate([`/${eventId}/participants`]);
    }

    private getColumnDefs(
        informationColumns: Array<TableColumnDef<unknown>>,
    ): Array<TableColumnDef<unknown>> {
        return [
            ...informationColumns,
            {
                isActionColumn: true,
                columnDef: 'actions',
                headerCellDef: '',
                cellDef: ({ id, participantCount }) => {
                    return new ActionCellDef(
                        [
                            {
                                icon: 'copy',
                                onClick: () => this.duplicateEvent(id),
                            },
                            {
                                icon: 'pencil',
                                onClick: () => this.editEvent(id),
                            },
                            {
                                icon: 'trash',
                                onClick:
                                    participantCount > 0
                                        ? () => {
                                              //no-op
                                          }
                                        : () => this.deleteEvent(id),
                            },
                            {
                                icon: 'users',
                                onClick: () => this.linkToParticipantList(id),
                            },
                        ],
                        { className: participantCount > 0 ? 'delete-disabled' : '' },
                    );
                },
            },
        ];
    }
}
