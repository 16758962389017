import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MyStobagSubheaderService } from '@stobag/mystobag-header';
import {
    AccountDTO,
    ActionCellDef,
    CustomDatePipe,
    StatusLabelCellDef,
    StatusLabelType,
    TableColumnDef,
    TextCellDef,
} from '@stobag/mystobag-shared';
import { combineLatest, map, Observable, shareReplay, switchMap, tap } from 'rxjs';
import { RegistrationStatus } from '../../enums/registration-status';


import { EventWorkshopParticipants } from '../../models/event-workshop-participants';
import { Participant } from '../../models/participant';
import { EventService } from '../../services/event.service';
import { UserAndAccountService } from '../../services/user-and-account.service';

@Component({
    selector: 'app-paritcipant-list',
    templateUrl: './participant-list.component.html',
    styleUrls: ['./participant-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ParticipantListComponent {
    event$ = this.activatedRoute.paramMap.pipe(
        map(paramMap => paramMap.get('eventId')),
        switchMap(eventId => this.eventService.getEventById(eventId)),
        shareReplay(1),
    );
    availableSlots$ = this.event$.pipe(
        map(ewp => ewp.capacity - ewp.participantCount),
        map(availableSlots => (availableSlots < 0 ? 0 : availableSlots)),
    );
    accounts$ = this.userAndAccountService.getAccounts();
    isSubheaderUpdated$ = combineLatest({
        pageName: this.translate.get('workshop.participants'),
        backButtonLabel: this.translate.get('event-manager.title'),
    }).pipe(
        tap(({ pageName, backButtonLabel }) => {
            this.subheaderService.updatePageName(pageName);
            this.subheaderService.showBackButton(backButtonLabel, '/', false);
        }),
    );
    columnDefs$ = this.getColumnDefs$();
    emptyIcon = 'users';

    constructor(
        private eventService: EventService,
        private activatedRoute: ActivatedRoute,
        private userAndAccountService: UserAndAccountService,
        private datePipe: CustomDatePipe,
        private translate: TranslateService,
        private router: Router,
        private subheaderService: MyStobagSubheaderService,
    ) {}

    private getColumnDefs$(): Observable<Array<TableColumnDef<Participant>>> {
        return combineLatest([this.event$, this.accounts$]).pipe(
            map(([event, accounts]) => this.getCoulmnDefs(event, accounts)),
        );
    }

    private getCoulmnDefs(
        event: EventWorkshopParticipants,
        accounts: AccountDTO[],
    ): Array<TableColumnDef<Participant>> {
        return [
            {
                headerCellDef: 'event-manager.participant-list.account',
                columnDef: 'account',
                cellDef: ({ accountNumber }) =>
                    new TextCellDef(accounts.find(a => a.accountNumber === accountNumber).name),
            },
            {
                headerCellDef: 'event-manager.participant-list.name',
                columnDef: 'firstName',
                cellDef: ({ firstname, name }) => new TextCellDef(firstname || name.split(' ')[1]),
            },
            {
                headerCellDef: 'event-manager.participant-list.firstName',
                columnDef: 'lastName',
                cellDef: ({ lastname, name }) => new TextCellDef(lastname || name.split(' ')[0]),
            },
            {
                headerCellDef: 'event-manager.participant-list.email',
                columnDef: 'email',
                cellDef: ({ email }) => new TextCellDef(email),
            },
            {
                headerCellDef: 'event-manager.participant-list.partnerStatus',
                columnDef: 'partnerStatus',
                cellDef: ({ accountNumber }) => {
                    const accountPartnerStatuses = accounts.find(
                        a => a.accountNumber === accountNumber,
                    )?.partnerStatuses;
                    const formattedPartnerStatus =
                        accountPartnerStatuses?.length > 0
                            ? this.translate.instant(
                                  `shared.partnerStatus.${accountPartnerStatuses[0]}`,
                              )
                            : '-';
                    return new TextCellDef(formattedPartnerStatus);
                },
            },
            {
                headerCellDef: 'event-manager.participant-list.rvl',
                columnDef: 'rvl',
                cellDef: ({ accountNumber }) =>
                    new TextCellDef(
                        accounts.find(a => a.accountNumber === accountNumber).contactPersonEmail,
                    ),
            },
            {
                headerCellDef: 'workshop.meal',
                columnDef: 'meal',
                cellDef: ({ meal }) => new TextCellDef(meal),
            },
            {
                headerCellDef: 'event-manager.participant-list.registrationDate',
                columnDef: 'registrationDate',
                cellDef: ({ registrationDate }) =>
                    new TextCellDef(this.datePipe.transform(new Date(registrationDate))),
            },
            {
                headerCellDef: 'event-manager.participant-list.registeredByEmail',
                columnDef: 'registeredByEmail',
                cellDef: ({ registeredByEmail }) => new TextCellDef(registeredByEmail),
            },
            {
                headerCellDef: 'workshop.status',
                columnDef: 'status',
                cellDef: ({ registrationStatus }) => {
                    const label = this.translate.instant(
                        `event-manager.participant-list.${registrationStatus}`,
                    );
                    return new StatusLabelCellDef(label, {
                        type: this.getStatusType(registrationStatus),
                    });
                },
            },
            {
                isActionColumn: true,
                columnDef: 'actions',
                headerCellDef: '',
                cellDef: ({ id }) => {
                    return new ActionCellDef([
                        {
                            icon: 'pencil',
                            onClick: () => {
                                this.modifyParticipant(event.id, id);
                            },
                        },
                    ]);
                },
            },
        ];
    }

    addParticipant(eventId: string) {
        this.router.navigate([`/${eventId}/participants/add`]);
    }

    modifyParticipant(eventId: string, participantId: string) {
        this.router.navigate([`/${eventId}/participants/${participantId}`]);
    }

    private getStatusType(status: RegistrationStatus): StatusLabelType {
        switch (status) {
            case RegistrationStatus.REGISTERED:
                return 'info';
            case RegistrationStatus.UNREGISTERED:
                return 'error';
            default:
                return null;
        }
    }

    downloadAsCsv(eventId: string) {
        this.eventService.getParticipantsAsCsv(eventId).subscribe(blob => {
            window.open(window.URL.createObjectURL(blob));
        });
    }
}
